import React from 'react'
import { Rating } from '@mui/material';
// import { Typography } from '@mui/material';
import styled from 'styled-components'

const Title = styled.h5`
  color: #4A5456;
  font-weight: 300;
  // position: relative;
  // width: 80%;
  // float: right;
  text-align: left;
`
const Rate = (props) => {
    const {title, value} = props
  return (
    <div className='list-group-item bg '>
      <div className='row'>
        <div className='col-sm-4' style={{margin: '0 0 0 5%'}}>
        {/* <Typography component="legend">Arabic:</Typography> */}
            <Title >{title}</Title>
        </div>
        <div className='col-sm-4' style={{textAlign: 'left'}}>
            <Rating 
              name='react-rate'
              value={value} 
              precision={0.5} 
              size='large' 
              readOnly />
        </div>
      </div>
        
    </div>
  )
}

export default Rate
