import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'
import logo from '../assest/img/logo.png'

import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaWhatsapp, FaPhone} from "react-icons/fa";

import { Row, Col, Container } from 'reactstrap'
// import { Link } from 'react-dom'
// import '../../MyCss.css'

const SocialShare = [
    {Social: <FaFacebookF size={25} /> , link: "https://www.facebook.com/hamza.zakieh.7"},
    {Social: <FaLinkedinIn size={25} /> , link: 'https://www.linkedin.com/in/hamzah-zakieh-543571198'},
    {Social: <FaWhatsapp size={25} /> , link: 'https://wa.me/+905079934036' },
    // {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaPhone size={25} /> , link: 'tel: +905079934036'},
]

const Footer = () => {
    return (
    <>
        <footer className='fontWight mt-auto' >
    
            <Row className=' bg-gradient font d-flex align-items-center justify-content-between'  >            
                <Col sm='4'  className='font'>
                    <ul className='align-items-center' style={{marginTop: '6px', padding: 0}}>
                        {SocialShare.map((val, index) =>(
                            <li className='circle'  key={index} >
                                <a  href={val.link}>{val.Social}</a>
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col sm='4'  className='font'>
                    <br/>
                        <a 
                            href="mailto: hamza.zakieh@gmail.com"
                            title='send Email to me'
                            target={'_blank'}
                            className=''
                        >
                            <h5 className='font'>
                                &copy; 2022 By Hamzah Zakieh
                            </h5>
                        </a>
                    <br />
                </Col>
            </Row>
        </footer>
    </>
  )
}

export default Footer


// {/* <div className='' style={{padding: '0 50px 0 50px'}}>
//             <Row className=' bg-gradient font d-flex align-items-center justify-content-between'  >
//                 <Col sm='4'   >
//                     <img src={logo} alt="logo" height={'30px'}/>
//                 </Col>
//                 <Col sm='4'  style={{paddingTop: '30px'}}>
//                     <ul className='align-items-center'>
//                         {SocialShare.map((val, index) =>(
//                             <li className='circle'  key={index}>
//                                 <a  href={val.link}>{val.Social}</a>
//                             </li>
//                         ))}
//                     </ul>
                    
//                 </Col>
//                 {/* <div className='col-sm-3'>
//                     <h6>Follow Me:</h6>
//                     <hr />
//                 </div> */} 

// {/* <footer className='fonttWight foot   mt-auto' style={{backgroundColor: ' #1E8383'}}>
//         <Row className=' bg-gradient font'   >
//             <Col sm='4' style={{marginTop: '1.5%'}}>
//                 <Row>
//                     <h5 className=''>Contact:</h5>
//                 </Row>
//                     <a
//                         className=""
//                         href="https://www.facebook.com/hamza.zakieh.7"
//                         rel='noreferrer'
//                         title='facebook page'
//                         // target={'_blank'}                        
//                     >
//                         <i className="foot-icon fa-brands fa-facebook"></i>
//                         {null}
//                     </a>
//                     <a 
//                         className=""
//                         href='https://www.linkedin.com/in/hamzah-zakieh-543571198'
//                         rel='noopener'
//                         title='LinkedIn'
//                     >
//                         <i className="foot-icon fa-brands fa-linkedin"></i>
//                         {null}
//                     </a>
//                     <a 
//                         className=""
//                         rel='noopener'
//                         title='whatsapp'
//                         href='https://wa.me/+905079934036'
//                     >
//                         <i className="foot-icon fa-brands fa-whatsapp"></i>
//                         {null}    
//                     </a>
//                     <a 
//                         href='tel: +905079934036' 
//                         className=''
//                         title='telefone'
//                         rel='noopener'
//                     >
//                         <i className=" foot-icon fa-sharp fa-solid fa-phone-volume"></i>
//                     </a>
//                     <hr />
//             </Col>
//             <Col sm='4' className='font' style={{marginTop: '1.5%'}}>
//                 <Row >
//                     <h5>Email:</h5>
//                 </Row>
                
//                 <a 
//                     href="mailto: hamza.zakieh@gmail.com"
//                     title='send Email to me'
//                     target={'_blank'}
//                     className=''
//                 >
//                     <span className='font'>hamza.zakieh@gmail.com</span> 
//                 </a>
//                 <hr />
//             </Col>
//             {/* <div className='col-sm-3'>
//                 <h6>Follow Me:</h6>
//                 <hr />
//             </div> */}
//             <Col sm='4'  className='font'>
//                 <br />
//                 <a 
//                     href="mailto: hamza.zakieh@gmail.com"
//                     title='send Email to me'
//                     target={'_blank'}
//                     className=''
//                 >
//                     <h5 className='font'>&copy; 2022 By Hamza Zakieh</h5>
                    
//                 </a>
//                 <br />
//                 <hr />
//             </Col>
//         </Row>
//     </footer> */}