import React from 'react'
import Card from '../card/Card'
import Rate from '../rate/Rate';
import styled from 'styled-components'
import ContainerResume from '../container/ContainerResume';

// const Title = styled.h2`
//   color: #4A5456;
//   font-weight: 300;
//   // text-align: left;
//   font-weight: 800; 

// `
const Head = styled.h1`
  color: #4A5456;
  font-weight: 300;
  margin: 0 0 100px 0;
  font-weight: 800; 

`


const Resume = () => {

  // bg= primary, secondary, success,danger, warning, info, light, dark
  // text= white, dark
  return (
    < >
    <div className='jumbotron text-center'>
      <Head>Resume</Head>
    </div>
      {/* <section id='education' className='container '>
        <div className='row'>
          <div className='col-sm-4' >
            <Title>Education</Title> 
          </div>
          <div className='col-sm-4 list-group'>
            <Card title='Master degree' date='2023-present'>
              Student in Cyber Security at Sakarya University.
            </Card>
            <Card title='Bechlore degree' date='2015-2022'>
              Bachelor Degree in computer engineering at Faculty of Electrical and Electronical Engineering, University of Aleppo.
            </Card>
        </div>
        </div>
      </section>
      <hr /> */}

      <ContainerResume id='Education' containerTitle='Education'>
        {/* cards or Rates */}
        
        <Card title='Master degree' date='2023-present'>
          Master degree in Cyber Security in Sakarya University.
        </Card>
        <Card title='Bechlore-degree' date='2015-2022'>
              Bachelor Degree in computer engineering at Faculty of Electrical and Electronical Engineering, University of Aleppo.
        </Card>
      </ContainerResume>
      <br />
      <ContainerResume id='Experiance' containerTitle='Experiance'>
        {/* cards */}
        <Card title='Amazon Virtual Assistant' date='2023-present'>
          I'm Working For Vallymart Company as amazon virtual assistant.
        </Card>
        {/* <Card title='Courses' date='present'>
          I watched and Learning HTML and CSS Courses and Javascript. after that I watched and learning <br />
          about ES6 then I come back to ReactJS To Refrech My Memory.<br />
          This project is my first project after that<br />
        </Card> */}
      </ContainerResume>
      <br />
      <ContainerResume id='Languages' containerTitle='Languages'>
        {/* cards */}
        <Rate title='Arabic:' value='5' />
        <Rate title='English:' value='4.5' />
        <Rate title='Turkish' value='4.5' />
      </ContainerResume>
      <br />
      <ContainerResume id='Programing-Languages' containerTitle='Programing Languages'>
        <Rate title='React:' value='5' />
          <Rate title='JS:' value='5' />
          <Rate title='TS:' value='4' />
          <Rate title='HTML:' value='5' />
          <Rate title='CSS:' value='5' />
          <Rate title='Pug:' value='4.5' />
          <Rate title='SCSS:' value='4' />
          <Rate title='Java:' value='3.5' />
          <Rate title='PHP' value='3.5' />
          <Rate title='Node.js' value= '4' />
          <Rate title='C++:' value='3.5' />
          <Rate title='C#:' value='3' />
      </ContainerResume>
      <br />
      <ContainerResume id='Another-skills' containerTitle='Another Skills' >
        <Rate title='Amazon Virtual Assistant' value= '5' />
      </ContainerResume>
      <br />

      {/* <hr />
      <section id='Languages'>
        <div className='container-mid'>
          <div className='card-left' >
            <Title>Langueges</Title> 
          </div>
          <div className='card-right'>
            <Rate title='Arabic:' value='5' />
            <Rate title='English:' value='3.5' />
            <Rate title='Turkish' value='2.5' />
          </div>
        </div>
      </section> */}
      {/* <section id='Programing Languages'>
        <div className='container-mid'>
          <div className='card-left' >
            <Title>Programming Languages</Title> 
          </div>
          <div className='card-right '>
            <Rate title='React:' value='4.5' />
            <Rate title='Javascript:' value='4.5' />
            <Rate title='Typescript:' value='4' />
            <Rate title='HTML:' value='5' />
            <Rate title='CSS:' value='4.5' />
            <Rate title='Pug:' value='4.5' />
            <Rate title='SCSS:' value='4' />
            <Rate title='Java:' value='3.5' />
            <Rate title='C++:' value='3.5' />
            <Rate title='C#:' value='3' />
          </div>
        </div>
      </section>         */}
    </>
  )
}

export default Resume