import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import FacebookSharp from '@mui/icons-material/FacebookSharp'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

import styled from 'styled-components'

const Links = styled.a`
  color: #4A5456;
  font-weight: 500;
  font-size: 40;
  text-align: left;
`
const Head = styled.h1`
  color: #4A5456;
  font-weight: 300;
  font-weight: 600; 
`

const Contact = () => {
  return (
    <>
      <Container className='' style={{height: '450px'}}>
        <Row>
          <Col sm='4'>
            <Head >
              Contact:
            </Head>
          </Col>
          <Col sm='8'>
            <Row className=''>
              <Col sm='6'>
                <Links 
                  href="https://m.facebook.com/hamza.zakieh.7"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className=''
                >
                  <FacebookSharp 
                    fontSize='large' 
                    color='primary' 
                    sx={{fontSize: 60}}
                    className=''
                  />
                  hamza zakieh
                </Links>
              </Col>
            </Row>
            <Row className=' bg'>
              <Col sm='6'>
                <Links 
                  href="https://www.linkedin.com/in/hamzah-zakieh-543571198"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className=''
                >
                  <LinkedInIcon 
                  fontSize='large' 
                  color='primary' 
                  sx={{fontSize: 60}} 
                />
                  hamzah-zakieh
                </Links>
              </Col>  
            </Row>
            <Row className=''>
              <Col sm='6'>
                <Links 
                  href="https://wa.me/+905079934036"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className=''
                >
                  <WhatsAppIcon 
                    fontSize='large' 
                    color='success' 
                    sx={{fontSize: 60}} 
                  />
                  WhatsApp Me
                </Links>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Contact

// {/* <div className='list-group-item bg ' >
//             <LinkedInIcon 
//               fontSize='large' 
//               color='primary' 
//               sx={{fontSize: 40}} 
//             />
//             <Links 
//               href="https://www.linkedin.com/in/hamzah-zakieh-543571198"
//               target="_blank" 
//               rel="noopener noreferrer"
//             >
//               hamzah-zakieh
//             </Links>
//           </div>
          
//           <div className='list-group-item bg' >
//             <WhatsAppIcon 
//               fontSize='large' 
//               color='success' 
//               sx={{fontSize: 40}}

//             />
//             <Links 
//               href="https://wa.me/905079934036"
//               target="_blank" 
//               rel="noopener noreferrer"
//             >
//               +90 507 993 40 36
//             </Links>
//           </div>
//         </div> */}