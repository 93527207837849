import React from 'react'
import { Row,Col } from 'reactstrap'
import styled from 'styled-components'
// import { Avatar } from '@mui/material';

const Title = styled.h3`
  color: #4A5456;
  font-weight: 700;
  width: 40%;
  float: left;
  text-align: left;

`
const Paragraph = styled.p`
  color: #4A5456;
  font-weight: 500;
  // width: 85%;
  // float: left;
  text-align: left;
`
const Links = styled.a`
  color: #1E8383;
  font-weight: 600;
  // font-size: 40%;
  text-align: left;
  float: right
`

const ContainerProject = (props) => {
    const {title, img, children, url} = props
  return (
    <section className='container-fluid' style={{ margin: '0 0 15px 0'}}>
        <Row>
            <Col sm='6'>
              <Row>
                <Col sm='12'>
                  <Title>
                    {title}:
                  </Title>
                  <Links className='' href={url} target={'_blank'} style={{textAlign: 'left'}}>
                    <span style={{color: '#1E8383', fontWeight: '600'}} >
                        visit the website {'>>'}
                      </span>
                  </Links>
                </Col>
              </Row>
              <Row>
                <Paragraph>
                    {children}
                </Paragraph>
              </Row>
            </Col>
            <Col sm='6'>
              <a href={url} target={'_blank'} >
                <img 
                  src={img}
                  className="img-thumbnail img-responsive" 
                  alt={`${title} project`}
                />
              </a>
                {/* <Avatar 
                    sx={{ width: 240 , height: 240 }}
                    size={280} 
                    variant="rounded"
                    alt={` ${title} project image`}
                    src={img}
                    className='img-responsive'
                /> */}
            </Col>
            <hr />
        </Row>
    </section>
  )
}

export default ContainerProject