import React from 'react';
import './App.css';
import './MyCss.css'
import {Routes, Route} from "react-router-dom"
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

// ** pages
import Home from './components/pages/Home'
import Resume from './components/pages/Resume'
import Project from './components/pages/Project';
import Contact from './components/pages/Contact';
import Error from './components/pages/Error';


function App() {
  return (
    <div className="App  d-flex flex-column min-vh-100">
          {/* 
            bg = dark, primary ,light
            variant = dark, light 
          */}
      <header className=''>
        <Navbar />
      </header>

      <main className='container-fluid' style={{ margin: '10% 0 0 0', alignItems:'center'}}>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/home' element={<Home/>}/>
          <Route exact path='resume' element={<Resume/>}/>
          <Route exact path='projects' element={<Project/>}/>
          <Route path='*' element={<Error/>}/>
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
