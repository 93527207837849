import React from 'react'
import {Link} from 'react-router-dom'
// import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap'
import logo from '../assest/img/logo.png'

export const MyNavbar = () => {
    // const {bg, variant} = props
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-gradient bg-gradient link-style nav-bottom" style={{backgroundColor: '#5F819A' }}>
        <>
          <Link to={'/'} className='link-style nav-link' >
            <img src={logo} alt="logo" height={'30px'}/>
          </Link>
          <Link to={'/'} className='link-style nav-link' >
            <span className="font" >My Profile</span>
          </Link>
          <button
            type='button'
            className="navbar-toggler btn " 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            aria-expanded="false" 
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to={'resume'} className='link-style nav-link'>
                  <span className=" font active" aria-current="page" >Resume</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={'projects'} className='link-style nav-link'>
                  <span className=" font active" aria-current="page" >Projects</span>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to={'contact'} className='link-style nav-link'>
                  <span className=" font active" aria-current="page" >Contacts</span>
                </Link>
              </li> */}
            </ul>
          </div>
        </>
      </nav>
    </>
  )
}
export default MyNavbar


// {/* <Navbar fixed="top" />
//         bg = dark, primary ,light
//         variant = dark, light
//     */}
//       {/* <Navbar className='row' fixed='top' bg={bg} style={{textAlign: 'center'}} variant={variant}>
//             <Container >
//                 <Navbar.Brand  className='col-sm-4' style={{textAlign: 'left', marginLeft: '10px'}}>
//                   <Link to="/">
//                     <img src={logo} alt='HZ' height='35' width='50' />
//                     <span style={{marginLeft: '5px'}}>
//                       Hamzah Zakieh
//                     </span>
//                   </Link>
//                 </Navbar.Brand>
//                 <Navbar.Toggle aria-controls="basic-navbar-nav" />
//                 <Navbar.Collapse id="basic-navbar-nav">
//                   <Nav className="col-sm-8 ">
//                     <div className='col-sm-3' style={{marginRight: '5px'}}>
//                       <Link to="resume">
//                         Resume
//                       </Link>
//                     </div>
//                     <div className='col-sm-3' style={{marginRight: '5px'}}>
//                       <Link to="project">
//                         Project  
//                       </Link>
//                     </div>
                    
//                     <div className='col-sm-3' style={{marginRight: '5px'}}>
//                       <Link to="contact">
//                         Contact
//                       </Link>
//                     </div>
//                   </Nav>
//                 </Navbar.Collapse>
//             </Container>
//         </Navbar> */}

