import React from 'react'
import {Link} from 'react-router-dom'
const Error = () => {
  return (
    <div>
        Error the path don't exest
        <br />
        <Link to={'/'} className='errorLinkStyle'>
            come back to Home
        </Link>
        
    </div>
  )
}

export default Error