import React from 'react'
import styled from 'styled-components'
import ContainerProject from '../container/ContainerProject'
// images
import protofol from '../assest/img/protofile.png'
import blog from '../assest/img/myBlog.png' 
import turkish from '../assest/img/turkish.png'

const Head = styled.h1`
  color: #4A5456;
  margin: 0 0 70px 0;
  font-weight: 800; 
`


const protofolUrl = 'https://inspiring-kataifi-dd98fc.netlify.app'
const blogUrl = 'https://eclectic-sable-97f366.netlify.app'
const turkishUrl ='https://eclectic-wisp-bcd652.netlify.app'

const Project = () => {
  return (
    <>
      <div className='jumbotron text-center' >
        <Head>Projects</Head>
      </div>

      <ContainerProject title='Türkçe Öğrenmek' img={turkish}  url={turkishUrl}>
        Türkçe Öğrenmek his idea is helping people to handle the turkish grammer and how to use this grammers and when.
        <br /><br />
        Note:
        <br />
        this is a simple version from website, I'm still working on it to add all grammer in the future, Adding supports for Arabic, English and more...
      </ContainerProject>

      <ContainerProject title='Protfolio' img={protofol} url={protofolUrl}>
        Protfolio Project to display Your Resume And the production you supplied
        so it's port to people to see your job an make contact with you.
      </ContainerProject>

      <ContainerProject title='Blog' img={blog} url={blogUrl}>
        My Blog project by using it the users can make account easly by loggining with google
        and making their blogs and delete it, more features in the future.
        <br /><br />
        Note:
        <br />
        it's not completed version I still working on it. 
      </ContainerProject>

    </>
  )
}

export default Project