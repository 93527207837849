import React from 'react'
import styled from 'styled-components'

const Title = styled.h3`
  color: #4A5456;
  font-weight: 500;
  // position: relative;
  // width: 70%;
  // float: right;
  text-align:left;
`
const Date = styled.h6`
  color: #4A5456;
  font-weight: 400;
  // margin: 6px 0;
  // position: relative;
  // width: 20%;
  // float: left;
  text-align:left;
`
const Description = styled.p`
  color: #4A5456;
  font-weight: 400;
  // position: relative;
  // width: 70%;
  // float: right;
  text-align: left
  `

// const ActionButton = styled.button`
//   // margin: 0 5px;
//   // padding: 8px 14px;
//   background: rgba(155, 155, 155, 0.2);
//   color: #fff;
//   cursor: pointer;
//   border: 1px solid #fff;
//   outline: 0;
//   font-weight: 300;
//   :hover {
//     opacity: 0.8;
//   }
// ` 

const Card = (props) => {
  const {title, date, children} = props
  return (
    <>
      <div className='list-group-item bg' style={{ color: '#fff',width:'100%', margin: '0 0 0 0'}}>
          <div className='row'>
            <Date className='col-sm-2'>{date}</Date>
            <Title className='col-sm-10'>{title}</Title>
          </div>
          <hr />
        <Description>
          {children}
        </Description>
      </div>
      <br />
    </>
  )
}


export default Card

//     // bg= primary, secondary, success,danger, warning, info, light, dark
//     // text= white, dark
//     // const {bg, text, header, title, children} = props
    
//   return (
//     <>
//       {/* <div class="card text-white bg-primary mb-3" style="max-width: 18rem;">
//         <div class="card-header">Header</div>
//         <div class="card-body">
//             <h5 class="card-title">Primary card title</h5>
//             <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//         </div>
//         </div> */}
//         <div class="card text-white bg-secondary mb-3" style="max-width: 18rem;">
//             <div class="card-header">Header</div>
//             <div class="card-body">
//                 <h5 class="card-title">Secondary card title</h5>
//                 <p class="card-text">bachelor degree in computer engineering from the university of aleppo</p>
//             </div>
//         </div>
//         {/* <div class="card text-white bg-success mb-3" style="max-width: 18rem;">
//             <div class="card-header">Header</div>
//             <div class="card-body">
//                 <h5 class="card-title">Success card title</h5>
//                 <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//             </div>
//         </div>
//         <div class="card text-white bg-danger mb-3" style="max-width: 18rem;">
//             <div class="card-header">Header</div>
//             <div class="card-body">
//                 <h5 class="card-title">Danger card title</h5>
//                 <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//             </div>
//         </div>
//         <div class="card text-dark bg-warning mb-3" style="max-width: 18rem;">
//             <div class="card-header">Header</div>
//             <div class="card-body">
//                 <h5 class="card-title">Warning card title</h5>
//                 <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//             </div>
//         </div>
//         <div class="card text-dark bg-info mb-3" style="max-width: 18rem;">
//             <div class="card-header">Header</div>
//             <div class="card-body">
//                 <h5 class="card-title">Info card title</h5>
//                 <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//             </div>
//         </div>
//         <div class="card text-dark bg-light mb-3" style="max-width: 18rem;">
//             <div class="card-header">Header</div>
//             <div class="card-body">
//                 <h5 class="card-title">Light card title</h5>
//                 <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//             </div>
//         </div>
//         <div class="card text-white bg-dark mb-3" style="max-width: 18rem;">
//             <div class="card-header">Header</div>
//             <div class="card-body">
//                 <h5 class="card-title">Dark card title</h5>
//                 <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//             </div>
//         </div> */}
//     </>
//   )
// }

// export default Card



// // import React from 'react';
// // import { makeStyles, withStyles } from '@material-ui/core/styles';
// // import Grid from '@material-ui/core/Grid';
// // import Box from '@material-ui/core/Box';
// // import Tooltip from '@material-ui/core/Tooltip';
// // import Avatar from '@material-ui/core/Avatar';
// // import Typography from '@material-ui/core/Typography';
// // import IconButton from '@material-ui/core/IconButton';
// // import CallMade from '@material-ui/icons/CallMade';

// // import { Row, Column, Item } from '@mui-treasury/components/flex';
// // import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';

// // const StyledTooltip = withStyles({
// //   tooltip: {
// //     marginTop: '0.2rem',
// //     backgroundColor: 'rgba(0,0,0,0.72)',
// //     color: '#fff',
// //   },
// // })(Tooltip);

// // const useBasicProfileStyles = makeStyles(({ palette }) => ({
// //   avatar: {
// //     borderRadius: 8,
// //     backgroundColor: '#495869',
// //   },
// //   overline: {
// //     fontSize: 10,
// //     textTransform: 'uppercase',
// //     letterSpacing: 1,
// //     color: '#8D9CAD',
// //   },
// //   name: {
// //     fontSize: 14,
// //     fontWeight: 500,
// //     color: '#495869',
// //   },
// // }));

// // const BasicProfile = props => {
// //   const styles = useBasicProfileStyles();
// //   return (
// //     <Row {...props}>
// //       <Item><Avatar className={styles.avatar}>S</Avatar></Item>
// //       <Item position={'middle'} pl={{ sm: 0.5, lg: 0.5 }}>
// //         <Typography className={styles.overline}>CREATOR</Typography>
// //         <Typography className={styles.name}>siriwatknp</Typography>
// //       </Item>
// //     </Row>
// //   );
// // };

// // const useCardHeaderStyles = makeStyles(() => ({
// //   root: { paddingBottom: 0 },
// //   title: {
// //     fontSize: '1.25rem',
// //     color: '#122740',
// //   },
// //   subheader: {
// //     fontSize: '0.875rem',
// //     color: '#495869',
// //   },
// // }));

// // const CardHeader = props => {
// //   const styles = useCardHeaderStyles();
// //   const iconBtnStyles = useSizedIconButtonStyles({ padding: 8, childSize: 20 });
// //   return (
// //     <Row {...props}>
// //       <Item position={'middle'}>
// //         <Typography className={styles.title}>
// //           <b>Firebase</b>
// //         </Typography>
// //         <Typography className={styles.subheader}>
// //           Similar to firebase theme
// //         </Typography>
// //       </Item>
// //       <Item position={'right'} mr={-0.5}>
// //         <StyledTooltip title={'See details'}>
// //           <IconButton classes={iconBtnStyles}>
// //             <CallMade />
// //           </IconButton>
// //         </StyledTooltip>
// //       </Item>
// //     </Row>
// //   );
// // };

// // const useStyles = makeStyles(() => ({
// //   card: {
// //     border: '2px solid',
// //     borderColor: '#E7EDF3',
// //     borderRadius: 16,
// //     transition: '0.4s',
// //     '&:hover': {
// //       borderColor: '#5B9FED',
// //     },
// //   },
// // }));

// // export const ShowcaseCardDemo = React.memo(function ShowcaseCard() {
// //   const styles = useStyles();
// //   const gap = { xs: 1, sm: 1.5, lg: 2 }
// //   return (
// //     <Grid container spacing={4} justify={'center'}>
// //       <Grid item xs={12} sm={4} md={3}>
// //         <Column className={styles.card} p={{ xs: 0.5, sm: 0.75, lg: 1 }} gap={gap}>
// //           <CardHeader />
// //           <Item>
// //             <Box minHeight={200} bgcolor={'#F4F7FA'} borderRadius={8} />
// //           </Item>
// //           <BasicProfile />
// //         </Column>
// //       </Grid>
// //       <Grid item xs={12} sm={8} lg={7}>
// //         <Row className={styles.card} p={{ xs: 0.5, sm: 0.75, lg: 1 }} gap={gap}>
// //           <Item grow>
// //             <Box minHeight={200} bgcolor={'#F4F7FA'} borderRadius={8} />
// //           </Item>
// //           <Column>
// //             <CardHeader />
// //             <BasicProfile position={'bottom'} />
// //           </Column>
// //         </Row>
// //       </Grid>
// //     </Grid>
// //   );
// // });
// // export default ShowcaseCardDemo