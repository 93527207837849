import React from 'react'
import { Row, Col} from 'reactstrap'
import styled from 'styled-components'
import { Avatar } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';
import { Link } from "react-router-dom";

// images
import avatar from '../../components/assest/img/my_photo.jpg'


const Title = styled.h3`
  color: #4A5456;
  font-weight: 500;
  // position: relative;
  // width: 70%;
  // float: right;
  text-align: left;
`

const Description = styled.p`
  color: #4A5456;
  font-weight: 400;
  // position: relative;
  width: 95%;
  // float: right;
  text-align: left
  `

const Home = () => {
  return (
      <>
        <Row>
          <Col sm='6'>
            <Avatar
              className=' img-responsive'
              style={{justifyItems: 'left', margin: '0 0 2% 10%'}}
              alt='Hamzah Zakieh'
              src={avatar}
              sx={{width: 280, height: 280}}
              sizes={`280`}
            />
            {/* <image 
              src={avatar}
              alt='Hamzah Zakieh'
              className='img-circle img-responsive'
            /> */}
          </Col>
          <Col sm='6'>
            <Row>
              <Title>Hamzah Zakieh</Title>
              <Description className='bg'>
                M.Sc. student at Sakarya University in Cyber Security. & B.Sc. in Computer Engineering. React.js & javascript Development, Developing & Design Websites.
              </Description>
            </Row> 
            <div className='d-flex circle-container1'>
              <div className='circle1'>
                <Link to={'/resume'}>
                  <Avatar sx={{bgcolor: deepOrange[500], width: 100, height: 100}}>Resume</Avatar>
                </Link>
              </div>
              <div className='circle2'>
                <Link to={'/projects'}>
                  <Avatar sx={{bgcolor: deepPurple[500], width: 100, height: 100}}>Project</Avatar>
                </Link>
              </div>
              <div className='circle3' >
                <Link to={'/contact'}>
                  <Avatar  sx={{bgcolor: '#2387F9', width: 100, height:100}}>Contact</Avatar>
                </Link>
              </div>
            </div>          
          </Col>      
        </Row>
      </>



  // {/* <Row>
  //               <Col sm='4'>
  //                 <Link className=''  to="resume">
  //                   <Avatar  sx={{bgcolor: deepOrange[500], width: 125, height: 125}}> Resume </Avatar>
  //                 </Link>
  //               </Col>
  //               <Col sm='4'>
  //                 <Link  className='' to="project">
  //                   <Avatar sx={{bgcolor: deepPurple[500], width: 125, height: 125}}>Project</Avatar>
  //                 </Link>
  //               </Col>
  //               <Col sm='4'>
  //                 <Link  className='' to="contact">
  //                   <Avatar sx={{bgcolor: '#2387F9', width: 125, height:125}}>Contact</Avatar>
  //                 </Link> 
  //               </Col>
  //             </Row> */}
        // {/* <div className='flow-right'>
        //   <div className='p-container'>
            
        //   </div>
        //   <div className='circle-container1'>
        //       <div className='circle1'>
        //         <a href='/resume'>
        //           <Avatar sx={{bgcolor: deepOrange[500], width: 100, height: 100}}>Resume</Avatar>
        //         </a>
        //       </div>
        //       <div className='circle2'>
        //         <a href="/project">
        //           <Avatar sx={{bgcolor: deepPurple[500], width: 100, height: 100}}>Project</Avatar>
        //         </a>
        //       </div>
        //   </div>
        //   <div className='circle3' >
        //     <a href="/contect">
        //       <Avatar  sx={{bgcolor: '#2387F9', width: 100, height:100}}>Contact</Avatar>
        //     </a>
        //   </div> */}
          
  )
}
export default Home