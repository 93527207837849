import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  color: #4A5456;
  font-weight: 300;
  // text-align: left;
  font-weight: 800; 

`

const ContainerResume = (props) => {
    const {id, containerTitle, children} =props
  return (
    <section id={id} className='container-fluid'>
        <div className='row'>
            <div className='col-sm-5'>
                <Title>
                    {containerTitle}
                </Title>
            </div>
            <div className='col-sm-7 list-group bg'>
                {children }
            </div>
        </div>
        <hr />
    </section>
  )
}

export default ContainerResume